import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import theme from '../../theme'
import { ProductCarouselItem } from './ProductCarousel'

type CardStackProps = {
  items: CardStackItem[]
  animate: boolean
  transitionDurationSeconds: number
  onClick: (item: CardStackItem) => void
}

type CardStackItem = ProductCarouselItem & {
  active: boolean
}

export default function CardStack(props: CardStackProps) {
  return (
    <CardStackContainer>
      {props.items.map(item => (
        <CardStackItem
          key={item.title}
          item={item}
          onClick={props.onClick}
          animate={props.animate}
          transitionDurationSeconds={props.transitionDurationSeconds}
        />
      ))}
    </CardStackContainer>
  )
}

const CardStackContainer = styled.div`
  display: flex;
  flex-direction: column;
`

function CardStackItem(props: {
  item: CardStackItem
  animate: boolean
  onClick: CardStackProps['onClick']
  transitionDurationSeconds: number
}) {
  const { animate, item } = props
  return (
    <Wrapper className={item.active ? 'active' : ''}>
      {item.image ? (
        <ImageContainer>
          <GatsbyImage
            className={`stack-image ${item.active ? 'active' : ''}`}
            image={item.image}
            alt={item.description}
          />
        </ImageContainer>
      ) : null}

      <CardStackItemContainer
        transitionDurationSeconds={props.transitionDurationSeconds}
        className={animate ? 'animate' : ''}
        onClick={() => props.onClick(props.item)}
      >
        <div className="info layout-index">
          <h5>{props.item.title}</h5>
          {props.item.active ? (
            <>
              <div className="description">{props.item.description}</div>
            </>
          ) : null}
        </div>
      </CardStackItemContainer>
    </Wrapper>
  )
}

const ImageContainer = styled.div`
  .stack-image {
    display: none;
    box-shadow: 0px 17px 60px 0px #17171a33, 0px 13px 30px 0px #0000001a;
    border-radius: 12px;
    margin: 12px 0 24px;

    @media screen and (max-width: ${theme.breakpoints.large}) {
      &.active {
        display: block;
      }
    }
  }
`

const Wrapper = styled.div`
  border-top: 1px solid #ebf1fb;
  margin: 0;
  box-sizing: border-box;

  /* Hide the divider border in special cases where it would look silly: */
  &:first-child,
  &.active + &,
  &.active,
  &:hover,
  &:hover + & {
    border-top-color: transparent;
  }
`

const CardStackItemContainer = styled.div<{
  transitionDurationSeconds: number
}>`
  background: none;
  position: relative;
  border-radius: 12px;
  width: 320px;
  overflow: hidden;

  @media screen and (max-width: ${theme.breakpoints.large}) {
    width: 100%;
  }

  ${Wrapper}.active & {
    background: ${theme.colors['hoverHighlight']};
  }

  div.info {
    padding: 24px 24px 20px 24px;
  }

  h5 {
    margin-bottom: 0;
  }

  div.description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0.25rem;
  }

  &:hover {
    background: #eef2f9;
    cursor: pointer;
  }

  ${Wrapper}.active &.animate::after {
    content: '';
    display: block;
    height: 4px;
    width: 0%;
    position: absolute;
    bottom: 0;
    background: #1a66d8;
    animation: ${props => props.transitionDurationSeconds}s progress linear;

    @keyframes progress {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
  }
`
