import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theme'
import CardStack from './CardStack'

type ProductCarouselProps = {
  items: ProductCarouselItem[]
  transitionDurationSeconds?: number
}

export type ProductCarouselItem = {
  id: string
  title: string
  description: string
  image?: IGatsbyImageData
}

export default function ProductCarousel(props: ProductCarouselProps) {
  const { transitionDurationSeconds = 10 } = props
  const [activeItem, setActiveItem] = useState<ProductCarouselItem>(
    props.items[0],
  )
  const [animate, setAnimate] = useState(true)
  const [activeItemPercentage, setActiveItemPercentage] = useState(0)
  const animationInterval = useRef<NodeJS.Timeout>()

  useEffect(() => {
    // Update the transition progress every second
    animationInterval.current = setInterval(
      () =>
        setActiveItemPercentage(
          activeItemPercentage + (1 / transitionDurationSeconds) * 100,
        ),
      1000,
    )
    return () => {
      gracefullyClearInterval(animationInterval.current)
    }
  }, [activeItemPercentage, transitionDurationSeconds])

  useEffect(() => {
    if (activeItemPercentage >= 100 && animate) {
      const currentIndex = props.items.findIndex(
        item => item.id === activeItem.id,
      )
      const nextItem = _.get(props.items, currentIndex + 1) ?? props.items[0]
      setActiveItemPercentage(0)
      setActiveItem(nextItem)
    }
  }, [activeItemPercentage, activeItem.id, props.items, animate])

  const onSelect = (item: ProductCarouselItem) => {
    setAnimate(false)
    gracefullyClearInterval(animationInterval.current)
    setActiveItem(item)
  }

  return (
    <CarouselLayout className="main-body-old-styles">
      <MediaContainer className="media-container">
        <CarouselHeroImageContainer>
          {activeItem.image ? (
            <GatsbyImage
              className="carousel-image"
              image={activeItem.image}
              alt={activeItem.description}
            />
          ) : null}
        </CarouselHeroImageContainer>
      </MediaContainer>

      <div style={{ flex: 1 }}>
        <CardStack
          items={props.items.map(item => ({
            ...item,
            active: item.id === activeItem.id,
          }))}
          animate={animate}
          onClick={onSelect}
          transitionDurationSeconds={transitionDurationSeconds}
        />
      </div>
    </CarouselLayout>
  )
}

function gracefullyClearInterval(interval: NodeJS.Timeout | undefined) {
  if (interval) {
    clearInterval(interval)
  }
}

const CarouselLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;

  position: relative;
  margin: 0;

  @media screen and (max-width: ${theme.breakpoints.large}) {
    flex-direction: column;
    padding: 24px 0;
  }
}
`

const MediaContainer = styled.div`
  flex: 4;

  @media screen and (max-width: ${theme.breakpoints.large}) {
    display: none;
  }
`

const CarouselHeroImageContainer = styled.div`
  .carousel-image {
    box-shadow: 0px 17px 60px 0px #17171a33, 0px 13px 30px 0px #0000001a;
    border-radius: 12px;
  }
`
